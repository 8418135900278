export const SET_COMPANIES = "SET_COMPANIES";

export default function (state = [], action) {
    if (action.type === SET_COMPANIES) {
        return action.companies;
    }
    return state;
}

export const setCompanies = (companies) => ({
    type: SET_COMPANIES,
    companies,
});
