import type {ProjectPageContext} from "@/util/store";
import {AxiosPromise} from "axios";
import {getApiClient} from "@/api/util";
import type {IntegrationResponse} from "@/api/integrations/model";



export default (ctx?: ProjectPageContext) => ({
    getPosIntegrations: (): AxiosPromise<IntegrationResponse> => getApiClient(ctx).get(`/v2/integrations/pos`),
    getWebIntegrations: (): AxiosPromise<IntegrationResponse> => getApiClient(ctx).get(`/v2/integrations/web`),
})
