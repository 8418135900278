import type {CountryConfigurationsResponse} from "@/api/countryConfigurations/model";

export const SET_COUNTRY_CONFIGURATIONS = "SET_COUNTRY_CONFIGURATIONS";

export default function (state: CountryConfigurationsState = null, action) {
    if (action.type === SET_COUNTRY_CONFIGURATIONS) {
        return action.countryConfigurations;
    }
    return state;
}

export const setCountryConfigurations = (countryConfigurations: CountryConfigurationsResponse) => ({
    type: SET_COUNTRY_CONFIGURATIONS,
    countryConfigurations,
});

export type CountryConfigurationsState = CountryConfigurationsResponse
