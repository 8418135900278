import type {IntegrationResponse} from "@/api/integrations/model";

export const SET_POS_INTEGRATIONS = "SET_POS_INTEGRATIONS";

export default function (state: PosIntegrationsState = null, action) {
    if (action.type === SET_POS_INTEGRATIONS) {
        return action.posIntegrations
    }
    return state;
}

export const setPosIntegrations = (posIntegrations: IntegrationResponse) => ({
    type: SET_POS_INTEGRATIONS,
    posIntegrations,
});

export type PosIntegrationsState = IntegrationResponse
