import type { ProjectPageContext } from '@/util/store';
import { AxiosPromise } from 'axios';
import { getApiClient } from '@/api/util';
import { prefixToIso2 } from '@/util/areaCodes';
import type { Contact, Organization } from '@/api/organization/model';
import { deepCopy, getSafeDeep } from '@/util/state';
import { getCurrentCountry } from '@/util/helper';

export default (ctx?: ProjectPageContext) => ({
    getOrganization: (companyId): AxiosPromise<Organization> =>
        getApiClient(ctx).get(`/v3/company/${companyId}/admin`),
    getCurrency: (companyId): AxiosPromise<Object> =>
        getApiClient(ctx).get(`/v3/company/${companyId}/currency`),
    getOrganizationContactList: (companyId): AxiosPromise<Array<Contact>> =>
        getApiClient(ctx)
            .get(`/v3/company/${companyId}/contacts`)
            .then((response) => {
                response.data = transformContactList(response.data);
                return response;
            }),
    deleteOrganizationContact: (contactId, companyId): AxiosPromise<Object> =>
        getApiClient(ctx).delete(`/v3/company/${companyId}/contact/${contactId}`),
    createOrganizationContact: (data, companyId): AxiosPromise<Object> =>
        getApiClient(ctx).post(`/v3/company/${companyId}/contact`, data),
    editOrganizationContact: (contactId, data, companyId): AxiosPromise<Object> =>
        getApiClient(ctx).put(`/v3/company/${companyId}/contact/${contactId}`, data),
    editOrganizationInfo: (data, companyId): AxiosPromise<Object> =>
        getApiClient(ctx).patch(`/v3/company/${companyId}/info`, data),
    legalDocuments: (companyId) => getApiClient(ctx).get(`/v3/company/${companyId}/legal/document`),
    handleNewsletter: (state, companyId): AxiosPromise<Object> =>
        getApiClient(ctx).patch(`/v3/company/${companyId}/newsletter/${state}`),
    getNewsletter: (companyId): AxiosPromise<Object> =>
        getApiClient(ctx).get(`/v3/company/${companyId}/newsletter`)
});

export const transformContactList = (contactList) => {
    return contactList.map((contact) => {
        const newContact = deepCopy(contact);
        const prefix = getSafeDeep(newContact, 'country_phone_prefix', [undefined])[0];
        if (prefix !== undefined) {
            newContact.country_phone_prefix_number = newContact.country_phone_prefix;
            newContact.country_phone_prefix = prefixToIso2[newContact.country_phone_prefix][0];
        } else {
            newContact.country_phone_prefix_number = undefined;
            newContact.country_phone_prefix = undefined;
        }
        return newContact;
    });
};

export const transformOrganization = (organization) => {
    const newOrganization = { ...organization };
    newOrganization.contacts.forEach((contact) => {
        contact.area_country_code = prefixToIso2[contact.country_phone_prefix]
            ? prefixToIso2[contact.country_phone_prefix][0]
            : undefined;
    });
    newOrganization.area_country_code = prefixToIso2[newOrganization.country_phone_prefix]
        ? prefixToIso2[newOrganization.country_phone_prefix][0]
        : undefined;
    return newOrganization;
};
