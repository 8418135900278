import type { UserState } from "@/redux/user";
import { combineReducers } from "redux";
import user from "@/redux/user";
import currencies from "@/redux/currencies";
import languages from "@/redux/languages";
import type { CurrenciesState } from "@/redux/currencies";
import type { LanguagesState } from "@/redux/languages";
import tags from "@/redux/tags";
import type { TagsState } from "@/redux/tags";
import type { CountryConfigurationsState } from "@/redux/countryConfigurations";
import countryConfigurations from "@/redux/countryConfigurations";
import kybStep from "@/redux/kybStep";
import type { KYBStepState } from "@/redux/kybStep";
import zendeskJwt from "@/redux/zendesk";
import posIntegrations from "@/redux/posIntegrations";
import webIntegrations from "@/redux/webIntegrations";
import type { ZendeskJwtState } from "@/redux/zendesk";
import type { PosIntegrationsState } from "@/redux/posIntegrations";
import type { WebIntegrationsState } from "@/redux/webIntegrations";
import organization from "@/redux/organization";
import type { OrganizationState } from "@/redux/organization";
import companyCurrency from "@/redux/companyCurrency";
import type { CompanyCurrencyState } from "@/redux/companyCurrency";
import countries from "@/redux/countries";
import type { CountriesState } from "@/redux/countries";
import companies from '@/redux/companies';
import selectedCompany from '@/redux/selectedCompany';

export default () => combineReducers({
    user,
    currencies,
    languages,
    tags,
    countryConfigurations,
    kybStep,
    zendeskJwt,
    posIntegrations,
    webIntegrations,
    organization,
    companyCurrency,
    countries,
    companies,
    selectedCompany
})

export type ProjectState = {
    user: UserState,
    currencies: CurrenciesState,
    languages: LanguagesState,
    tags: TagsState,
    countryConfigurations: CountryConfigurationsState,
    kybStep: KYBStepState,
    zendeskJwt: ZendeskJwtState,
    posIntegrationsState: PosIntegrationsState,
    webIntegrationsState: WebIntegrationsState,
    organizationState: OrganizationState,
    companyCurrencyState: CompanyCurrencyState,
    countriesState: CountriesState,
    selectedCompany: any
}
