import { createStore, compose, Store } from 'redux'
import getReducers from '@/redux'
import type { ProjectState } from "@/redux"
import type { NextPageContext } from "next/types/index"

// eslint-disable-next-line no-undef
const composeWithDevTools = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export const initializeStore = (preloadedState = {}) => createStore(
    getReducers(),
    preloadedState,
    composeWithDevTools(),
)

export type ProjectStore = Store<ProjectState>

export type ProjectPageContext = NextPageContext & {reduxStore: ProjectStore}
