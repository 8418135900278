import type {KYBStep} from "@/api/kyb/model";

export const SET_KYB_STEP = "SET_KYB_STEP";

export default function (state: KYBStepState = null, action) {
    if (action.type === SET_KYB_STEP) {
        return action.kybStep;
    }
    return state;
}

export const setKybStep = (kybStep: KYBStep) => ({
    type: SET_KYB_STEP,
    kybStep,
});

export type KYBStepState = KYBStep;
