import type {IntegrationResponse} from "@/api/integrations/model";

export const SET_WEB_INTEGRATIONS = "SET_WEB_INTEGRATIONS";

export default function (state: WebIntegrationsState = null, action) {
    if (action.type === SET_WEB_INTEGRATIONS) {
        return action.webIntegrations
    }
    return state;
}

export const setWebIntegrations = (webIntegrations: IntegrationResponse) => ({
    type: SET_WEB_INTEGRATIONS,
    webIntegrations,
});

export type WebIntegrationsState = IntegrationResponse
