import type { User } from '@/api/auth/model';
import auth from '@/api/auth/auth';
import { setUser } from '@/redux/user';
import { setCompanies } from '@/redux/companies';
import { selectCompany } from '@/redux/selectedCompany';
import type { ProjectPageContext } from '@/util/store';
import cookies from 'next-cookies';
import getConfig from 'next/config';
import { Router, eraseCookie, getCookie } from '@/util/helper';
import NProgress from 'nprogress';
import { setCurrencies } from '@/redux/currencies';
import currencies from '@/api/currencies/currencies';
import languages from '@/api/languages/languages';
import { setLanguages } from '@/redux/languages';
import tags from '@/api/tags/tags';
import { setTags } from '@/redux/tags';
import countryConfigurations from '@/api/countryConfigurations/countryConfigurations';
import { setCountryConfigurations } from '@/redux/countryConfigurations';
import kyb from '@/api/kyb/kyb';
import { setKybStep } from '@/redux/kybStep';
import JWT from 'jsonwebtoken';
import i18n from '../i18n';
import { setZendeskJwt } from '@/redux/zendesk';
import { setPosIntegrations } from '@/redux/posIntegrations';
import { setWebIntegrations } from '@/redux/webIntegrations';
import integrations from '@/api/integrations/integrations';
import { setOrganization } from '@/redux/organization';
import organization from '@/api/organization/organization';
import { setCompanyCurrency } from '@/redux/companyCurrency';
import { getSafeDeep } from '@/util/state';
import countries from '@/api/countries/countries';
import { setCountries } from '@/redux/countries';
import { getApiClient } from '@/api/util';
import { SELECTED_COMPANY, SELECTED_HOLISTIC_COMPANY } from '@/util/constants';
import { getOrInitializeStore } from '@/util/reduxUtils';

const { publicRuntimeConfig } = getConfig();
const sessionCookieName = process.env.sessionCookieName || 'sessionid';

export const reloadLangSensitiveReduxValues = async (
    setTags,
    setCountries,
    language = 'en'
): Promise<void> => {
    const [tagList, countryList] = await Promise.all([
        tags().getTags(language),
        countries().getCountries(language)
    ]);
    setTags(tagList.data);
    setCountries(countryList.data);
};

export const preloadGlobalResources = async (ctx: ProjectPageContext): Promise<void> => {
    try {
        const store = ctx.reduxStore;
        const language = getSafeDeep(ctx.req, 'i18n.language', false);
        const storeState = store.getState();

        const [
            currencyList,
            languageList,
            tagList,
            countryConfigurationList,
            countryList
        ] = await Promise.all([
            cachePromiseWrapper(currencies(ctx).getCurrencies, storeState, 'currencies'),
            cachePromiseWrapper(languages(ctx).getLanguages, storeState, 'languages'),
            cachePromiseWrapper(() => tags(ctx).getTags(language), storeState, 'tags'),
            cachePromiseWrapper(
                countryConfigurations(ctx).getCountryConfigurations,
                storeState,
                'countryConfigurations'
            ),
            cachePromiseWrapper(
                () => countries(ctx).getCountries(language),
                storeState,
                'countries'
            )
        ]);
        store.dispatch(setCurrencies(currencyList));
        store.dispatch(setCountries(countryList));
        store.dispatch(setLanguages(languageList));
        store.dispatch(setTags(tagList));
        store.dispatch(setCountryConfigurations(countryConfigurationList));
    } catch (err) {
        console.log(err);
    }
};

export const preloadStore = async (ctx: ProjectPageContext): Promise<void> => {
    const store = ctx.reduxStore;
    const lang = geti18nLanguage(ctx);
    getApiClient(ctx).defaults.headers.common['Accept-Language'] = lang;
    getApiClient(ctx).defaults.headers.common.Language = lang;

    const { selectedCompany } = store.getState();
    const settings = (await auth(ctx).getSettings(lang)).data;
    const userCompanies = (await auth(ctx).getUserCompanies()).data;

    if (!selectedCompany) {
        const cookieSelectedCompany = cookies(ctx)[SELECTED_COMPANY];

        if (cookieSelectedCompany) {
            store.dispatch(selectCompany(cookieSelectedCompany));
        } else {
            store.dispatch(selectCompany(userCompanies[0]));
        }
    }

    if (!ctx.req) {
        NProgress.inc();
    }

    store.dispatch(setUser(settings));
    store.dispatch(setCompanies(userCompanies));

    if (!ctx.req) {
        NProgress.inc();
    }
};

export const merchantRoleIds = [
    '00000000-ffff-ffff-ffff-ffffffffffff',
    '00000000-ffff-ffff-ffff-000000000000'
];

export const redirectIfAuth = async (ctx: ProjectPageContext): Promise<void> => {
    if (ctx.req && !cookies(ctx)[sessionCookieName]) {
        return true;
    }
    try {
        if (!ctx.req) {
            NProgress.inc();
        }

        let roles;
        try {
            const { data } = await auth(ctx).getRole();
            roles = data;
        } catch (e) {
            roles = [];
        }
        const hasMerchantRole = roles.some(({ id }) => merchantRoleIds.includes(id));
        if (hasMerchantRole) {
            await redirectTo(ctx, '/');
            return false;
        }
        return true;
    } catch (e) {
        if (!(e.response && e.response.status === 403)) {
            // throw e
            return true;
        }
    }
};

const cachePromiseWrapper = (apiCall, storeState, key, apiDataKey) =>
    new Promise((resolve, reject) => {
        if (!storeState[key]) {
            apiCall().then((response) => {
                if (apiDataKey) resolve(response.data[apiDataKey]);
                else resolve(response.data);
            });
        } else {
            resolve(storeState[key]);
        }
    });

export const updateStore = async (store, companyId = '') => {
    try {
        await kyb()
            .getKYBStep(companyId)
            .then((res) => res.data)
            .then((data) => store.dispatch(setKybStep(data)));
        await organization()
            .getOrganization(companyId)
            .then((res) => res.data)
            .then((data) => store.dispatch(setOrganization(data)));
        await organization()
            .getCurrency(companyId)
            .then((res) => res.data)
            .then((data) => store.dispatch(setCompanyCurrency(data)));
    } catch (err) {
        console.log(err);
    }
};

/**
 * Get authenticated user or redirect to login page
 * @param ctx {ProjectPageContext}
 */
export const requireAuthenticatedUser = async (ctx: ProjectPageContext): Promise<User> => {
    if (ctx.req && !cookies(ctx)[sessionCookieName]) {
        await redirectTo(ctx, '/login');
        return false;
    }

    let roles;
    try {
        const { data } = await auth(ctx).getRole();
        roles = data;
    } catch (e) {
        roles = [];
    }
    const hasMerchantRole = roles.some(({ id }) => merchantRoleIds.includes(id));
    if (!hasMerchantRole) {
        await redirectTo(ctx, '/login');
        return false;
    }
    try {
        await preloadStore(ctx);
        const store = ctx.reduxStore;
        const storeState = store.getState();

        const [
            kybStep,
            organizationData,
            posIntegrations,
            webIntegrations,
            companyCurrency
        ] = await Promise.all([
            kyb(ctx).getKYBStep(storeState.selectedCompany.id),
            cachePromiseWrapper(
                () => organization(ctx).getOrganization(storeState.selectedCompany.id),
                storeState,
                'organization'
            ),
            cachePromiseWrapper(
                integrations(ctx).getPosIntegrations,
                storeState,
                'posIntegrations'
            ),
            cachePromiseWrapper(
                integrations(ctx).getWebIntegrations,
                storeState,
                'webIntegrations'
            ),
            cachePromiseWrapper(
                () => organization(ctx).getCurrency(storeState.selectedCompany.id),
                storeState,
                'companyCurrency'
            )
        ]);

        storeState.kybStep = kybStep.data;
        await store.dispatch(setKybStep(kybStep.data));
        await store.dispatch(setPosIntegrations(posIntegrations));
        await store.dispatch(setWebIntegrations(webIntegrations));
        await store.dispatch(setOrganization(organizationData));
        await store.dispatch(setCompanyCurrency(companyCurrency));
        return true;
    } catch (e) {
        if (e.response && e.response.status === 403) {
            await redirectTo(ctx, '/login');
            return false;
        }
        throw e;
    }
};

export const geti18nLanguage = (ctx) =>
    ctx.req ? getSafeDeep(ctx.req, 'language', 'en') : getSafeDeep(i18n, 'i18n.language', 'en');

export async function redirectTo(ctx, path) {
    if (ctx.req) {
        ctx.res.writeHead(302, { Location: path }).end();
        ctx.res.finished = true;
    } else {
        await Router.push(path);
    }
}

export async function conditionalRedirectTo(ctx, path) {
    if (ctx.res) {
        ctx.res
            .writeHead(302, {
                Location: path,
                'Content-Type': 'text/html; charset=utf-8'
            })
            .end();

        return {};
    }
    await Router.push(path);
}

export const handleLogout = () => {
    auth().logout();
    eraseCookie('sessionid');
    eraseCookie(SELECTED_COMPANY);
    eraseCookie(SELECTED_HOLISTIC_COMPANY);
    window.location.replace(`${publicRuntimeConfig.ellyManagerURL}/login`);
};
