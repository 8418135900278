import type {TagsResponse} from "@/api/tags/model";

export const SET_TAGS = "SET_TAGS";

export default function (state: TagsState = null, action) {
    if (action.type === SET_TAGS) {
        return action.tags;
    }
    return state;
}

export const setTags = (tags: TagsResponse) => ({
    type: SET_TAGS,
    tags,
});

export type TagsState = TagsResponse
