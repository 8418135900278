import getConfig from 'next/config';
import i18n from '@/i18n';
import RouterNext from 'next/router';
import type { NextRouter } from 'next/router.d.ts';
import i18Next from 'i18next';

import BHCActive from '@/assets/bhcActive.svg';
import BHCInactive from '@/assets/bhcInactive.svg';
import BTCActive from '@/assets/btc.svg';
import BTCInactive from '@/assets/btcInactive.svg';
import ETHActive from '@/assets/eth.svg';
import ETHInactive from '@/assets/ethInactive.svg';
import GOCActive from '@/assets/goc.svg';
import GOCInactive from '@/assets/gocInactive.svg';
import TEZActive from '@/assets/tezActive.svg';
import TEZInactive from '@/assets/tezInactive.svg';
import LTCActive from '@/assets/ltcActive.svg';
import LTCInactive from '@/assets/ltcInactive.svg';
import BNBActive from '@/assets/bnbActive.svg';
import BNBInactive from '@/assets/bnbInactive.svg';
import VIBActive from '@/assets/vibActive.svg';
import VIBInactive from '@/assets/vibInactive.svg';
import USDTActive from '@/assets/usdtActive.svg';
import USDTInactive from '@/assets/usdtInactive.svg';
import fiatIcon from '@/assets/fiatIcon.svg';
import { getSafeDeep } from '@/util/state';
import _ from 'lodash';

const { publicRuntimeConfig } = getConfig();

export const getCurrentLanguage = () => {
    return i18Next.language || 'en';
};

export const { withTranslation, useTranslation, appWithTranslation } = i18n;

export const redirectNewTab = (url) => {
    if (window) window.open(url, '_blank');
};

export const eraseCookie = (name) => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
};

export const getCookie = (name) => {
    const pairs = document.cookie.split(';');
    const pair = pairs.find((cookie) => cookie.split('=')[0].trim() === name);
    if (!pair) return '';
    return pair.split('=')[1];
};

export const setCookie = (name, value, domain) => {
    if (domain) {
        document.cookie = `${name}=${value};domain=${publicRuntimeConfig.cookieDomain};path=/`;
    } else {
        document.cookie = `${name}=${value}`;
    }
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
};

// formatting Comma Dat => 1,000.00 Antigua and Barbuda
// formatting Dot Comma => 1.000,00 DE
// currency.symbol property in currency
const commaDotCurrencies = [
    'XCD',
    'GHS',
    'KES',
    'NGN',
    'MXN',
    'INR',
    'ILS',
    'JPY',
    'MYR',
    'KRW',
    'LKR',
    'THB',
    'GBP',
    'AUD',
    'NZD'
];

export const parse = (currency = 'EUR', number, toFixed = 0) => {
    if (commaDotCurrencies.includes(currency)) {
        return parseFloat(number).toLocaleString('en-US', {
            minimumFractionDigits: toFixed,
            maximumFractionDigits: toFixed
        });
    }
    return parseFloat(number).toLocaleString('de-DE', {
        minimumFractionDigits: toFixed,
        maximumFractionDigits: toFixed
    });
};

export const getDecimalDeliminator = (currency) => {
    const dot = parse(currency, 0, 2).split('').includes('.');
    return dot ? '.' : ',';
};

export const getThousandsDeliminator = (currency) => {
    const decimal = getDecimalDeliminator(currency);
    return decimal == '.' ? ',' : '.';
};

export function numberWithCommas(x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export function getMathSign(num = 0) {
    switch (Math.sign(num)) {
        case -1:
            return `-`;
        case -0:
            return `-`;
        case 0:
            return `+`;
        case 1:
            return `+`;
        default:
            return '';
    }
}

export const getIcon = (symbol, status = 'active') => {
    const icons = {
        BTC: {
            active: BTCActive,
            inactive: BTCInactive
        },
        BCH: {
            active: BHCActive,
            inactive: BHCInactive
        },
        ETH: {
            active: ETHActive,
            inactive: ETHInactive
        },
        GOC: {
            active: GOCActive,
            inactive: GOCInactive
        },
        XTZ: {
            active: TEZActive,
            inactive: TEZInactive
        },
        LTC: {
            active: LTCActive,
            inactive: LTCInactive
        },
        BNB: {
            active: BNBActive,
            inactive: BNBInactive
        },
        VIB: {
            active: VIBActive,
            inactive: VIBInactive
        },
        USDT: {
            active: USDTActive,
            inactive: USDTInactive
        }
    };
    if (icons[symbol]) {
        return icons[symbol][status];
    }
    return fiatIcon;
};

export const scrollToFaulty = (ref, event) => {
    const formLength = ref.current.length;

    let isFirst = true;
    for (let i = 0; i < formLength; i++) {
        const elem = ref.current[i];
        if (!elem.validity.valid) {
            // TODO: refactor this hackaround - issue with HTML5 form validation relative jump and sticky header
            // Timeout will hang - sacrificed to make reuse easier
            if (isFirst && elem == event.target) {
                setTimeout(function () {
                    window.scrollBy(0, -100);
                }, 50);
            }
            isFirst = false;
        }
    }
};

export function isCryptoCurrency(currencies, activeSettlementId) {
    return !!currencies.find(({ id }) => id == activeSettlementId)?.crypto;
}

export const scrollToFaultyElement = (ref, event) => {
    const elem = event.target;
    if (ref.current === false) {
        setTimeout(function () {
            window.scrollBy(0, -150);
            ref.current = false;
        }, 50);
    }
    ref.current = true;
};

export const getFAQLink = () => {
    const fqs = {
        en: 'https://web.gocrypto.com/hc/en-us/sections/360010090172-Merchant-Dashboard',
        hr:
            'https://web.gocrypto.com/hc/hr/sections/360010090172-Nadzorna-plo%C4%8Da-Merchant-Dashboard',
        it: 'https://web.gocrypto.com/hc/it/sections/360010090172-Merchant-Dashboard',
        ja: 'https://web.gocrypto.com/hc/ja/sections/360010090172-Merchant-Dashboard',
        sl:
            'https://web.gocrypto.com/hc/sl/sections/360010090172-Nadzorna-plo%C5%A1%C4%8Da-Merchant-Dashboard'
    };

    return fqs[getCurrentLanguage()];
};

export async function disableItemByName(
    name = '',
    suffix = '',
    disableFlag = 'disabled',
    arr = [],
    condition = false
) {
    return arr
        ?.map((item) =>
            condition(item)
                ? {
                      ...item,
                      [name]: `${item[name]} ${suffix}`,
                      [disableFlag]: true
                  }
                : item
        )
        ?.sort((prevItem, item) => prevItem[name].includes(suffix) - item[name].includes(suffix));
}

export const Router: NextRouter = RouterNext;

export const supportedLanguages = ['en', 'hr', 'sl', 'it', 'ja'];
export const supportedReportLanguages = ['en', 'sl', 'hr', 'ja'];
export const supportedLegalLanguages = ['en', 'sl'];
