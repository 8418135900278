import type { Organization } from "@/api/organization/model";

export const SET_ORGANIZATION = "SET_ORGANIZATION";

export default function (state: OrganizationState = null, action) {
    if (action.type === SET_ORGANIZATION) {
        return action.organization;
    }
    return state;
}

export const setOrganization = (organization: Organization) => ({
    type: SET_ORGANIZATION,
    organization,
});


export type OrganizationState = Organization
