import { getApiClient } from "@/api/util";
import { AxiosPromise } from "axios"
import type {ProjectPageContext} from "@/util/store"
import type {LanguagesResponse} from "@/api/languages/model";
import type {KYBStep} from "@/api/kyb/model";
import {transformContactList} from "@/api/organization/organization";
import {deepCopy} from "@/util/state";

export default (ctx?: ProjectPageContext) => ({
    getRepresentativeData: (companyId): AxiosPromise<Object> => getApiClient(ctx).get(`/v3/company/${companyId}/profile/representative`),
    submitRepresentativeData: (data, companyId): AxiosPromise<Object> => getApiClient(ctx).post(`/v3/company/${companyId}/profile/representative`, data),

    getCertifiedDocumentList: (companyId): AxiosPromise<any> => getApiClient(ctx).get(`/v3/company/${companyId}/profile/documents`),
    createCertifiedDocument: (data, companyId): AxiosPromise<Object> => getApiClient(ctx).post(`/v3/company/${companyId}/profile/documents`, data),
    deleteCertifiedDocument: (documentId, companyId): AxiosPromise<Object> => getApiClient(ctx).delete(`/v3/company/${companyId}/profile/documents/${documentId}`),
    getCertifiedDocument: (documentId, companyId): AxiosPromise<Object> => getApiClient(ctx).get(`/v3/company/${companyId}/profile/documents/${documentId}`),

    getKYBStep: (companyId): AxiosPromise<KYBStep> => getApiClient(ctx).get(`/v3/company/${companyId}/profile/step`),
    nextStep: (companyId): AxiosPromise<Object> => getApiClient(ctx).post(`/v3/company/${companyId}/profile/step/next`),
    prevStep: (companyId): AxiosPromise<Object> => getApiClient(ctx).post(`/v3/company/${companyId}/profile/step/prev`),

    getBeneficialOwnerList: (companyId): AxiosPromise<Object> => getApiClient(ctx).get(`/v3/company/${companyId}/profile/owners`).then(response =>{ response.data = transformBeneficialOwnerList(response.data); return response;}),
    getBeneficialOwner: (ownerId, companyId): AxiosPromise<Object> => getApiClient(ctx).get(`/v3/company/${companyId}/profile/owners/${ownerId}`),
    deleteBeneficialOwner: (ownerId, companyId): AxiosPromise<Object> => getApiClient(ctx).delete(`/v3/company/${companyId}/profile/owners/${ownerId}`),
    createBeneficialOwner: (data, companyId): AxiosPromise<Object> => getApiClient(ctx).post(`/v3/company/${companyId}/profile/owners`, data),
    editBeneficialOwner: (ownerId, data, companyId): AxiosPromise<Object> => getApiClient(ctx).put(`/v3/company/${companyId}/profile/owners/${ownerId}`, data),
})


const transformBeneficialOwnerList = (data) => {
    if (data && data.length > 0) {
        return data.map(owner => {
            const parsedOwner = deepCopy(owner);
            parsedOwner.ownership_percentage = parseFloat(parsedOwner.ownership_percentage);
            if (parsedOwner.temporary_address === null) delete parsedOwner.temporary_address;
            return parsedOwner;
        })
    }

    return data;
};
