const NextI18Next = require('next-i18next').default;

module.exports = new NextI18Next({
    defaultLanguage: 'en',
    otherLanguages: ['en', 'hr', 'sl', 'it', 'ja'],
    serverLanguageDetection: true,
    localePath: typeof window === 'undefined' ? 'public/locales' : 'locales',
    detection: {
        lookupQuerystring: 'lang',
        lookupCookie: 'language',
        order: ['querystring', 'cookie'],
        cookieDomain: process.env.COOKIE_DOMAIN,
        caches: ['cookie']
    }
});
