

export const SET_COUNTRIES = "SET_COUNTRIES";

export default function (state: CountriesState = null, action) {
    if (action.type === SET_COUNTRIES) {
        return action.countries;
    }
    return state;
}

export const setCountries = (countries: Object) => ({
    type: SET_COUNTRIES,
    countries,
});

export type CountriesState = Object;
