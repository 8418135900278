import { getApiClient } from "@/api/util";
import type { AuthResponse, User } from "@/api/auth/model"
import { AxiosPromise } from "axios"
import type { ProjectPageContext } from "@/util/store"
import { getCurrentLanguage } from '@/util/helper';

export default (ctx?: ProjectPageContext) => ({
    getUser: (): AxiosPromise<User> => getApiClient(ctx).get(`/v2/auth`),
    getSettings: (lang): AxiosPromise<Object> => getApiClient(ctx).get(`/v2/user/settings?lng_code=${lang}`, {
        // headers: {
        //     'Language': lang
        // }
    }),
    getUserCompanies: () => {
        return getApiClient(ctx).get(`/v3/company`)
    },
    login: (username: string, password: string, remember = false): AxiosPromise<Object> => {
        let body = {
            username,
            password
        };
        if (remember) body.remember_me = true;
        return getApiClient(ctx).post(`/v2/auth`, body)
    },
    getRole: (): AxiosPromise<Object> => getApiClient(ctx).get('/v3/rbac/user/current/roles'),
    changePassword: (old_password, new_password, companyId): AxiosPromise<Object> => getApiClient(ctx).post(`/v3/company/${companyId}/password/change`, { old_password, new_password }),
    logout: (): AxiosPromise => getApiClient(ctx).delete("/v2/auth"),
    resetPassword: (email): AxiosPromise => getApiClient(ctx).post(`/v3/company/password/reset`,
        {
            email
        }, {
        headers: { "Accept-Language": getCurrentLanguage() }
    }),
    verifyPasswordResetToken: (email, token): AxiosPromise => getApiClient(ctx).post(`/v3/company/password/verify-reset-token/`, {
        email,
        token
    }),
    setNewPassword: (email, token, password, lng = 'en'): AxiosPromise => getApiClient(ctx).post(`/v3/company/password/new/${token}/`, {
        new_password: password,
        confirm_password: password,
        email
    }, {
        headers: { "Accept-Language": getCurrentLanguage() }
    }),
    verifyAccountBlockToken: (email, token): AxiosPromise => getApiClient(ctx).post(`/v3/company/password/verify-reset-token/`, {
        email,
        token
    }),
    blockAccount: token => {
        return getApiClient(ctx).post(`/v3/login/block/${token}`)
    },
    cancelAccountBlock: token => {
        return getApiClient(ctx).post(`/v3/block/cancel/${token}`)
    }
})
