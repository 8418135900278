

export const SET_ZENDESK_JWT = "SET_ZENDESK_JWT";

export default function (state: ZendeskJwtState = null, action) {
    if (action.type === SET_ZENDESK_JWT) {
        return action.zendeskJwt;
    }
    return state;
}

export const setZendeskJwt = (zendeskJwt: string) => ({
    type: SET_ZENDESK_JWT,
    zendeskJwt,
});

export type ZendeskJwtState = string
